import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'

// Images
import pattern1 from '../../../images/pattern/pt1.png';
import aboutPic1 from '../../../images/slider/about_us.jpg';
import Count from '../../elements/counter/counter-sensor';

class About1 extends Component{
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return(
			<>
				<div className="section-area section-sp2 parallax" style={{backgroundImage: "url("+pattern1+")"}}>
					<div className="container">
						<div className="row">
							<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
								<div className="heading-bx">
									<h2 className="title-head">Firmamız Hakkında</h2>
									<p>2001 yılında kurulan Altuntur, Türkiye’den yurt dışına kaliteli hırdavat ve tekstil ürünleri ihraç eden öncü bir firmadır. 20 yılı aşkın tecrübemizle müşterilerimize güvenilir hizmet sunmaya ve onların ihtiyaçlarını karşılamaya adanmış durumdayız.</p>
									<p>Uzman ekibimiz titizlikle seçilmiş tedarikçilerden elde ettiğimiz ürünleri müşterilerimize ulaştırır. Hedefimiz, hem Türk ihracatçılarına hem de yurt dışındaki alıcılara katma değer yaratmaktır. Müşteri memnuniyetini her zaman ön planda tutuyoruz.</p>
									<p>Son teknoloji makine parkımız ve kalite kontrol süreçlerimiz sayesinde ürünlerimiz her zaman yüksek standartlarda olup, uluslararası normlara uygundur. Titizlikle yerine getirdiğimiz teslim süreçleriyle de müşterilerimizin güvenini kazanıyoruz.</p>
									<p>Gelecekte öncü ve yenilikçi bir ihracat firması olarak yerimizi daha da sağlamlaştırmayı hedefliyoruz. Müşterilerimizin ihtiyaçları bizim için her zaman önceliklidir.</p>
								</div>
								<Link to={"about"} className="btn button-md radius-xl">Devamını Oku</Link>
							</div>
							<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
								<div className="about-imgbox">
									<img src={aboutPic1} alt=""/>
									<div className="about-year">
										<h2><Count counter={24}/>+</h2>
										<h5>Yıllık Serüven</h5>
										<Link to={"#"} onClick={this.openModal} className="popup-youtube videoplay-bx"><i className="fa fa-play"></i> <span>Videoyu Oynat</span></Link>
										<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='' onClose={() => this.setState({isOpen: false})} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default About1;