import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'
import ModalVideo from 'react-modal-video'

// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Elements
import Counter from "../../elements/counter/counter2";

// Images
import bannerPic2 from '../../../images/banner/banner2.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import test from '../../../images/test/3.jpg';

class About extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
				<Header/>
				
				<div className="page-content bg-white">
					<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<span className="banner-sm-title">Altuntur</span>
								<h1 className="text-white">HAKKIMIZDA</h1>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-12 col-md-12 wow fadeIn" data-wow-delay="0.3s">
										<div className="heading-bx">
											<h2 className="title-head m-b0"><b className="altunoz">Altuntur</b> Hakkında?</h2>
												<p>2001 yılında kurulan Altuntur, Türkiye’den yurt dışına kaliteli hırdavat ve tekstil ürünleri ihraç eden öncü bir firmadır. 20 yılı aşkın tecrübemizle müşterilerimize güvenilir hizmet sunmaya ve onların ihtiyaçlarını karşılamaya adanmış durumdayız.</p>
												<p>Uzman ekibimiz titizlikle seçilmiş tedarikçilerden elde ettiğimiz ürünleri müşterilerimize ulaştırır. Hedefimiz, hem Türk ihracatçılarına hem de yurt dışındaki alıcılara katma değer yaratmaktır. Müşteri memnuniyetini her zaman ön planda tutuyoruz.</p>
												<p>Son teknoloji makine parkımız ve kalite kontrol süreçlerimiz sayesinde ürünlerimiz her zaman yüksek standartlarda olup, uluslararası normlara uygundur. Titizlikle yerine getirdiğimiz teslim süreçleriyle de müşterilerimizin güvenini kazanıyoruz.</p>
												<p>Gelecekte öncü ve yenilikçi bir ihracat firması olarak yerimizi daha da sağlamlaştırmayı hedefliyoruz. Müşterilerimizin ihtiyaçları bizim için her zaman önceliklidir.</p>
												<p>Ürün yelpazemizde bulunan tüm hırdavat ve tekstil ürünleri, özenle seçilmiş tedarikçilerden ve üreticilerden temin edilmektedir. Metal, plastik, ahşap gibi çeşitli hırdavat ürünlerimizin yanı sıra, ev tekstili, döşemelik kumaşlar, perde ve yer örtüleri gibi tekstil ürünlerimiz de mevcuttur.</p>
												<p>Hırdavat ürünlerimiz, inşaat sektöründe yerli ve yabancı firmaların ihtiyaçlarını karşılamak üzere tasarlanmış olup, sağlam ve dayanıklı yapısı, üstün kalitesi ve rekabetçi fiyatları ile öne çıkmaktadır. Tekstil ürünlerimizde de özellikle Avrupa ülkelerinin damgasını taşıyan modern desen ve renkleri tercih ederek, dünya çapında talep gören ürünler sunmaktayız.</p>
												<p>İhracat departmanımız, uluslararası ticaret ve gümrük mevzuatı konularında uzmandır. Müşterilerimizin talepleri doğrultusunda, istenen ülkeye özel olarak paketleme, etiketleme, belgelendirme ve sevkiyat işlemlerini titizlikle yerine getirmekteyiz. Hedefimiz daima müşterilerimizin beklentilerini karşılayan bir hizmet anlayışı sunmaktır.</p>
												<p>Geleceğe güven ve istikrarla bakan Altuntur olarak, ihracat alanındaki iddiamızı daha da ileri taşımaya ve ülke ekonomisine sağladığımız katkıyı artırmaya devam edeceğiz.</p>
											<Link to={"contact"} className="mt-3 btn button-md radius-xl">İLETİŞİME GEÇ</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
						<div className="section-area bg-gray section-sp1">
							<div className="container">
								<Counter />
							</div>
						</div>
						
					
					</div>
				</div>
				
				<Footer/>
				
				
			</>
		);
	}
}

export default About;