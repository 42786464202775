import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Images
import sliderImg1 from "../../../images/slider/altuntur.jpg"
import sliderImg2 from "../../../images/slider/slider-textile.jpg"
import sliderImg3 from "../../../images/slider/slider-hardware.jpg"
class Slider1 extends Component{
	
	render(){
		
		const settings = {
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		
		return(
			<>
				
				<Slider {...settings} className="tt-slider slider-one owl-btn-1 slider-sp0">
					<div className="slider-item">
						<div className="slider-thumb ovbl-middle">
							<img src={sliderImg1} alt=""/>
						</div>
						<div className="slider-content text-white">
							<div className="content-inner">
								<h6 className="sub-title">Altuntur</h6>
								<h2 className="title">Since 2001<br/> 2001 Yılından Bu Yana Firmamızı <br/>Sizler İçin Daha İleri Taşıyoruz.</h2>
							</div>
						</div>
					</div>			
					<div className="slider-item">
						<div className="slider-thumb ovbl-middle">
							<img src={sliderImg2} alt=""/>
						</div>
						<div className="slider-content text-white">
							<div className="content-inner">
								<h6 className="sub-title">Altuntur</h6>
								<h2 className="title">2001 Yılından Bu Yana Firmamızı <br/>Sizler İçin Daha İleri Taşıyoruz.</h2>
							</div>
						</div>
					</div>		
					<div className="slider-item">
						<div className="slider-thumb ovbl-middle">
							<img src={sliderImg3} alt=""/>
						</div>
						<div className="slider-content text-white">
							<div className="content-inner">
								<h6 className="sub-title">Altuntur</h6>
								<h2 className="title">2001 Yılından Bu Yana Firmamızı <br/>Sizler İçin Daha İleri Taşıyoruz.</h2>
							</div>
						</div>
					</div>				
				</Slider>
			</>
		);
	}
}

export default Slider1;
