import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'
import ModalVideo from 'react-modal-video'
import data from '../../../subeler.json';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Elements
import Counter from "../../elements/counter/counter2";

// Images
import bannerPic2 from '../../../images/banner/banner2.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import test from '../../../images/test/3.jpg';

class About extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false,
            data: [],
            query: ''
		}
		this.openModal = this.openModal.bind(this)
	}

    componentDidMount() {
        this.fetchBranches();
    }

    fetchBranches() {
        fetch('https://api.altuntur.com/api/App/GetBranchList')
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            this.setState({ data: data.data });
          })
          .catch((error) => {
            console.error('There has been a problem with your fetch operation:', error);
          });
    }

	openModal () {
		this.setState({isOpen: true})
	}

    filterData = () => {
        const { data, query } = this.state;
        if (!query) {
            return data;
        }
        return data.filter((item) => {
            return Object.keys(item).some((key) => {
                const value = item[key].toString().toLowerCase();
                return value.includes(query.toLowerCase());
            });
        });
    };
    
    handleSearchChange = (e) => {
        this.setState({ query: e.target.value });
    };
    
	render(){
        const filteredData = this.filterData();
		return (
			<>
				<Header/>
				
				<div className="page-content bg-white">
					<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<span className="banner-sm-title">Altuntur</span>
								<h1 className="text-white">ŞUBELERİMİZ</h1>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="row align-items-center">
                                <div className='col-md-12'>

                                <div className='quick-search mb-4'>
                                    <FontAwesomeIcon className='search-icon' icon={faMagnifyingGlass} />
                                    <input
                                        type="search"
                                        placeholder="Hızlı Arama..."
                                        value={this.state.query}
                                        onChange={this.handleSearchChange}
                                        />
                                </div>


                                    {filteredData && filteredData.map(sube => (
                                        <div className='branches-info' key={sube.id}> 
                                            <h4><FontAwesomeIcon className='title-icon' icon={faAngleRight} /> {sube.title}</h4>
                                            <div className='box'>
                                                <p><FontAwesomeIcon icon={faPhone} /> {sube.phone1}</p>
                                                <p><FontAwesomeIcon icon={faPhone} /> {sube.phone2}</p>
                                                <p><FontAwesomeIcon icon={faEnvelope} /> {sube.mail}</p>
                                                <p><FontAwesomeIcon icon={faMapMarkerAlt} /> {sube.address1}</p>
                                                <p><FontAwesomeIcon icon={faGlobe} />  {sube.city}</p>
                                            </div>
                                            <div className='box2'>
                                                <iframe
                                                    style={{ border: 0 }}
                                                    loading="lazy"
                                                    allowFullScreen
                                                    src={sube.location}
                                                    title="Harita"
                                                    ></iframe>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
								</div>
							</div>
						</div>
						
						
						<div className="section-area bg-gray section-sp1">
							<div className="container">
								<Counter />
							</div>
						</div>
						
					
					</div>
				</div>
				
				<Footer/>
				
				
			</>
		);
	}
}

export default About;