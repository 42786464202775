import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'
import ModalVideo from 'react-modal-video'

// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Elements
import Counter from "../../elements/counter/counter2";

// Images
import bannerPic2 from '../../../images/banner/banner2.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import test from '../../../images/test/3.jpg';

class Billing extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
				<Header/>
				
				<div className="page-content bg-white">
					<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<span className="banner-sm-title">Altuntur</span>
								<h1 className="text-white">FATURA BİLGİLERİ</h1>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-12 col-md-12 wow fadeIn" data-wow-delay="0.3s">
										<div className="heading-bx">
											<h2 className="title-head mb-3"><b className="altunoz">Fatura Bilgilerimiz</b></h2>
											<table class="table">
                                                <tbody>
                                                    <tr>
                                                        <td>Ünvan:</td>
                                                        <td>ALTUNTUR TEKSTİL İÇ VE DIŞ TİCARET LTD.ŞTİ.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Vergi Dairesi:</td>
                                                        <td>ŞEHİTKAMİL</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Vergi Numarası:</td>
                                                        <td>068 011 8282</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Adres:</td>
                                                        <td>Aydınlar Mah. 03063 Nolu Cad. No 25/1 ( Altunkaynak Plaza ) Şehitkâmil, Gaziantep, Turkey</td>
                                                    </tr>
                                                </tbody>
                                            </table>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
						<div className="section-area bg-gray section-sp1">
							<div className="container">
								<Counter />
							</div>
						</div>
						
					
					</div>
				</div>
				
				<Footer/>
				
				
			</>
		);
	}
}

export default Billing;