import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import logo from '../../../images/1.png';
import logoWhite from '../../../images/1.png';
import adv from '../../../images/adv/adv.jpg';

class Header extends Component{
	
	componentDidMount() {
		
		// Search Form Popup
		var searchBtn = document.getElementById("quik-search-btn")
        var searchForm = document.querySelector(".nav-search-bar")
        var closeBtn = document.getElementById("search-remove")
	
		searchBtn.addEventListener('click',function(){
            searchForm.classList.add("show")
        })
        closeBtn.addEventListener('click',function(){
            searchForm.classList.remove("show")
        })

		var menuIcon = document.querySelector(".menuicon")
		var menuLinks = document.querySelector(".menu-links")		
		var menuClose = document.getElementById("menuClose")
			
		menuIcon.addEventListener('click',function(){
			menuLinks.classList.add("show")
		})
		menuClose.addEventListener('click',function(){
			menuLinks.classList.remove("show")
		})

        // Mobile Submenu Open Close Function
		var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
		for (var y = 0; y < navMenu.length; y++) {
			navMenu[y].addEventListener('click', function () { menuClick(this) });
		}
	
		function menuClick(current) {
			const active = current.classList.contains("open")
			navMenu.forEach(el => el.classList.remove('open'));
			
			if(active){
				current.classList.remove('open') 
				console.log("active")
			} else{
				current.classList.add('open');
				console.log("close")
			}
		}

    }
	
	render(){
		return(
			<>
				{/* <!-- Header Top ==== --> */}
				<header className="header rs-nav header-transparent">
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container-fluid clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<Link to={"/"}><img src={logoWhite} alt=""/></Link>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								<div className="secondary-menu">
									<div className="secondary-inner">
										<ul>
											<li className="search-btn"><button id="quik-search-btn" type="button" className="btn-link"></button></li>
										</ul>
									</div>
								</div>
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
									<div className="menu-logo">
										<Link to={"/"}><img src={logo} alt=""/></Link>
									</div>
									<ul className="nav navbar-nav">	
										<li><Link to={""}>Anasayfa</Link></li>
										<li className="has-mega-menu "><Link to="#">Kurumsal<i className="fa fa-chevron-down"></i></Link>
											<ul className="mega-menu">
												<li>
													<ul>
														<li><Link to={"about"}><span>Hakkımızda</span></Link></li>
														<li><Link to={"vission-mission"}><span>Vizyon & Misyon</span></Link></li>
														<li><Link to={"human-policy"}><span>İnsan Kaynakları</span></Link></li>
														<li><Link to={"#"}><span>Belgeler</span></Link></li>
														<li><Link to={"billing"}><span>Fatura Bilgileri</span></Link></li>
														<li><Link to={"financial"}><span>Mali Bilgiler</span></Link></li>
														<li><Link to={"kvkk"}><span>KVKK</span></Link></li>
													</ul>
												</li>
											</ul>
										</li>
										<li><Link to={"branches"}>ŞUBELERİMİZ</Link></li>
										<li><Link to={"contact"}>İLETİŞİM</Link></li>
									</ul>
									<div className="navbar-footer">
										<ul className="nav-social-link">
											<li><a target="_blank" href="https://www.facebook.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
											<li><a target="_blank" href="https://www.google.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-google-plus"></i></a></li>
											<li><a target="_blank" href="https://www.linkedin.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
											<li><a target="_blank" href="https://twitter.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-twitter"></i></a></li>
										</ul>
										<p className="copyright-text">© 2022 Altuntur. Tüm Hakları Saklıdır.</p>
									</div>
									<div className="menu-close" id="menuClose">
										<i className="ti-close"></i>
									</div>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> */}
					<div className="nav-search-bar">
						<form action="#">
							<input name="search" defaultValue="" type="text" className="form-control" placeholder="Type to search"/>
							<span><i className="ti-search"></i></span>
						</form>
						<span id="search-remove"><i className="ti-close"></i></span>
					</div>
				</header>
				{/* <!-- Header Top END ==== --> */}
			</>
		);
	}
}

export default Header;
