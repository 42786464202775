import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import IconImg1 from "../../images/icon/contact/icon1.png"
import IconImg2 from "../../images/icon/contact/icon2.png"
import IconImg3 from "../../images/icon/contact/icon3.png"
import Logo from "../../images/logo.png"

class ContactSidebar extends Component{
	
	componentDidMount() {
		
		// Contact Sidebar
        var asideBtn = document.querySelector(".slideinfo")
        var contactSidebar = document.querySelector(".contact-sidebar")
        var closeBtn = document.getElementById("clase-btn")

        asideBtn.addEventListener('click',function(){
            contactSidebar.classList.add("open")
        })

        closeBtn.addEventListener('click',function(){
            contactSidebar.classList.remove("open")
        })        
    }
	
	render(){
		return(
			<>
				<div className="slideinfo">
					<ul className="slide-social">
						<li>SOSYAL MEDYA</li>
						<li className="sc-link"><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
						<li className="sc-link"><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
						<li className="sc-link"><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
					</ul>
					<Link to={"contact"} className="slider-contact btn contact-sidebtn">İLETİŞİME GEÇ</Link>
				</div>
				<div className="contact-sidebar content-scroll">
					<div className="inner">
						<div className="logo">
							<img src={Logo} alt=""/>
						</div>
						<h4 className="title">İletişim Bilgilerimiz</h4>
						<ul className="contact-infolist">
							<li>
								<img src={IconImg1} alt=""/>
								<h6 className="contact-title">İletişim Numarası</h6>
								<p>+90 342 231 17 20 <br/>+90 342 231 17 21</p>
							</li>
							<li>
								<img src={IconImg2} alt=""/>
								<h6 className="contact-title">Email Adresi</h6>
								<Link to="#">info@altuntur.com</Link><br/>
								<Link to="#">support@altuntur.com</Link>
							</li>
							<li>
								<img src={IconImg3} alt=""/>
								<h6 className="contact-title">Adres</h6>
								<p>AYDINLAR MAH. 03063 NOLU CAD. NO:25/1 <br />ŞEHİTKAMİL / GAZİANTEP / TÜRKİYE</p>
							</li>
						</ul>
					</div>
				</div>
				<div className="contact-sidebtn close" id="clase-btn">
					<i className="ti-close"></i>
				</div>
			</>
		);
	}
}

export default ContactSidebar;