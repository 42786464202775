import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'
import ModalVideo from 'react-modal-video'

// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Elements
import Counter from "../../elements/counter/counter2";

// Images
import bannerPic2 from '../../../images/banner/banner2.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import test from '../../../images/test/3.jpg';

class VissionMission extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
				<Header/>
				
				<div className="page-content bg-white">
					<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<span className="banner-sm-title">Altuntur</span>
								<h1 className="text-white">Vizyon - Misyon</h1>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-12 wow fadeIn" data-wow-delay="0.3s">
										<div className="heading-bx">
											<h2 className="title-head m-b0"><b className="altunoz">Vizyonumuz</b></h2>
											<p className="m-b0">
											Vizyonumuz, hırdavat ve tekstil ihracatında bölgenin lider firmaları arasında yer almaktır. Bu doğrultuda sürekli gelişim prensibiyle yenilikçi ürün ve hizmetler sunmayı, verimlilik ve karlılığımızı artırmayı hedefliyoruz. Müşteri memnuniyetinde ve ihracat pazar payımızda sürdürülebilir artış yakalamayı amaçlıyoruz. Gelecekte global ölçekte tanınan ve güven veren bir marka haline gelmek vizyonumuzdur.
                                            </p>
                                            <br />
                                            <h2 className="title-head m-b0"><b className="altunoz">Misyonumuz</b></h2>
											<p className="m-b0">
                                            Altuntur olarak, Türkiye’den kaliteli hırdavat ve tekstil ürünlerini dünyanın dört bir yanına ihraç ederek ülke ekonomisine katkı sağlamak temel misyonumuzdur. Müşterilerimizin beklentilerini anlayarak, ihtiyaçlarına en uygun çözümleri sunmak için çalışıyoruz. Titizlik, dürüstlük ve güvenilirlik ilkeleri çerçevesinde faaliyet gösteriyoruz.
                                            </p>

											<p>Misyon ve vizyonumuz doğrultusunda, paydaşlarımıza ve içinde bulunduğumuz topluma katma değer yaratmaya devam edeceğiz. Sürdürülebilir ihracat hedeflerimize ulaşmada tüm çalışanlarımızın yetkinliklerinden faydalanacağız.</p>
										</div>
									</div>
									<div className="col-lg-6 col-md-12 wow fadeIn" data-wow-delay="0.6s">
										<div className="about-img-box">
											<img src={test} alt=""/>
											<img  alt=""/>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
						<div className="section-area bg-gray section-sp1">
							<div className="container">
								<Counter />
							</div>
						</div>
						
					
					</div>
				</div>
				
				<Footer/>
				
				
			</>
		);
	}
}

export default VissionMission;